import React from 'react';
import styles from './Servicos.module.css';

const Servicos = () => {

  const handleWhatsClick = () => {
     window.open("https://wa.me/558620180456", "_blank");
   };

  return (
    <section className={styles.container} id='servicos'>
        <div className={styles.title}> <h1>SERVIÇOS</h1></div>

        <div className={styles.type}><h1>SMART</h1></div>
        <div className={styles.smart}> 
            <div className={styles.smartpd1}>
                <img src="./img/ia.png" alt="" />
                <h3>INTELIGÊNCIA ARTIFICIAL</h3>
                <p>Liberte o potencial da sua empresa com soluções de Inteligência Artificial. Automatize processos,
                     personalize experiências e obtenha insights profundos para decisões mais inteligentes.
                      Com a expertise da Smart Data Solutions, 
                    transforme seu futuro digital.</p>
            </div>
            <div className={styles.smartpd2}>
                <img src="./img/www.png" alt="" />
                <h3>ANÁLISE PREDITIVA</h3>
                <p>Usa algoritmos de análise preditiva e machine learning para prever tendências e 
                    comportamentos futuros com base em dados históricos, 
                    ajudando sua empresa a tomar decisões estratégicas e otimizar operações.</p>
                    <button className={styles.button} type="button" onClick={handleWhatsClick}>SAIBA MAIS</button>  
           </div>
           
            </div>
        <div className={styles.type}><h1>DATA</h1></div>
        <div className={styles.smart}>
            <div className={styles.datapd1}>
                <img src="./img/database.png" alt="" />
                <h3>INTEGRAÇÃO DE DADOS</h3>
                <p>Na nuvem que conecta e sincroniza informações de diferentes fontes e sistemas, 
                    facilitando uma gestão unificada e eficiente dos dados empresariais.
                    Transforme seus dados em insights estratégicos com nossa solução escalável e segura.</p>
            </div>
            <div className={styles.datapd2}>
                <img src="./img/graphics.png" alt="" />
                <h3>SOLUÇÕES DE BI</h3>
                <p>Transforme dados em insights acionáveis com o BI. 
                    Visualize informações complexas de maneira intuitiva e tome decisões estratégicas com confiança. 
                    Potencialize seu negócio com análises poderosas e precisas.</p>
                    <button className={styles.button} type="button" onClick={handleWhatsClick}>SAIBA MAIS</button>
            </div>
           
        </div>

        <div className={styles.type}><h1>SOLUTIONS</h1></div>
        <div className={styles.smart}>
            <div className={styles.solutionspd1}>
                <img src="./img/sistemaweb.png" alt="" />
                <h3>DESENVOLVIMENTO WEB</h3>
                <p>Na Smart Data Solutions, construímos sistemas web confiáveis e 
                    escaláveis para garantir desempenho superior e segurança máxima. 
                    Com nossa tecnologia de ponta, prepare sua empresa para um futuro de crescimento e inovação.</p>
            </div>
            <div className={styles.solutionspd2}>
                <img src="./img/aplicativos.png" alt="" />
                <h3>APLICATIVOS</h3>
                <p>Potencialize seu negócio com aplicativos personalizados da Smart Data Solutions. 
                    Desenvolvemos soluções intuitivas que transformam dados em insights acionáveis, 
                    ajudando você a tomar decisões estratégicas e 
                    impulsionar o crescimento da sua empresa.</p>
                    <button className={styles.button} type="button" onClick={handleWhatsClick}>SAIBA MAIS</button>
            </div>
            
        </div>
    </section>
  )
}

export default Servicos;




