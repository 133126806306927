import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarFixed, setNavbarFixed] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setNavbarFixed(true);
        document.body.classList.add('fixed-navbar');
      } else {
        setNavbarFixed(false);
        document.body.classList.remove('fixed-navbar');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.navbar} ${navbarFixed ? styles.fixed : ''}`}>
      <img src="./img/sds7.jpg" alt="logo da data solutions3" className={styles.image} />
      <div className={styles.menuIcon} onClick={toggleMenu}>
        <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
        <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
        <div className={`${styles.bar} ${menuOpen ? styles.open : ''}`}></div>
      </div>
      <div className={`${styles.menu} ${menuOpen ? styles.active : ''}`}>
        <a href="#home" className={styles.ancora}>Home</a>
        <a href="#servicos" className={styles.ancora}>Serviços</a>
        <a href="#sobrenos" className={styles.ancora}>Sobre nós</a>
        <a href="#metodologia" className={styles.ancora}>Metodologia</a>
        <a href="#contato" className={styles.ancora}>Contato</a>
      </div>
    </div>
  );
}

export default Navbar;



