import React from 'react';
import styles from './Metodologia.module.css';

const Metodologia = () => {
  const handleWhatsClick = () => {
    window.open("https://wa.me/558620180456", "_blank");
  };
  return (
    <section className={styles.container} id='metodologia'>
        <div className={styles.content}>
           <h1 className={styles.title}>NOSSA METODOLOGIA DE GESTÃO EMPRESARIAL</h1>

             <div className={styles.info}>
              <p>
                Na Smart Data Solutions, transformamos visões ambiciosas em conquistas tangíveis com metodologias ágeis e eficazes. 
                Integramos o <span>OKR, SCRUM e KANBAN</span> para garantir uma gestão de projetos completa.
                Utilizamos o <span>OKR(Objectives and Key Results)</span> para definir e alcançar metas estratégicas, 
                promovendo alinhamento e engajamento. Com o priorizamos entregas iterativas e colaborativas,
                garantindo flexibilidade e adaptação contínua. O KANBAN nos permite visualizar o fluxo de trabalho, 
                otimizando a eficiência e minimizando desperdícios.
                Descubra como nossas abordagens podem impulsionar sua equipe e projetos,
                elevando sua capacidade de inovação e sucesso no mercado. 
                Na Smart Data Solutions, levamos sua estratégia empresarial ao próximo nível com precisão e inovação.
              </p>
            </div>
               <button type='button' className={styles.button} onClick={handleWhatsClick}> SAIBA MAIS </button>
         </div>
         <img src="./img/method.png" alt="Method.png" className={styles.image}/>
    </section>
  )
}

export default Metodologia;
