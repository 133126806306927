import Navbar from "./components/layout/Navbar";
import Home from "./components/Home";
import Servicos from "./components/Servicos";
import Sobrenos from "./components/Sobrenos";
import Trofeus from "./components/Trofeus";
import Metodologia from "./components/Metodologia";
import Contato from "./components/Contato";



function App() {
  return (
    <div className="App">
       <Navbar/>
       <Home/>
       <Servicos/>
       <Sobrenos/>
       <Trofeus/>
       <Metodologia/>
       <Contato/>
    </div>
  );
}

export default App;
