import React from 'react';
import styles from './layout/Home.module.css';

const Home = () => {
  const handleWhatsClick = () => {
    window.open("https://wa.me/558620180456", "_blank");
  };

  return (
    <div className={styles.container} id='home'>

      <div className={styles.info}>
         <h3>FÁBRICA DE SOFTWARES SOB DEMANDA</h3>
         <h1>TRANSFORME SUA VISÃO EM REALIDADE DIGITAL COM A AJUDA DA TECNOLOGIA </h1>
         <p>Descubra como nossos softwares personalizados podem revolucionar seus processos e impulsionar seu crescimento. 
            Conheça a inovação que faz a diferença na Smart Data Solutions.</p>

         <button type='button' className={styles.button} onClick={handleWhatsClick}> SAIBA MAIS</button>

        
      </div>
      <div className={styles.imagecontainer}>
        <img src="./img/laptop.jpg" alt="" className={styles.image}/>
      </div>
    </div>
  )
}

export default Home;
