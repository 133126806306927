import React from 'react';
import styles from './Trofeus.module.css';

const Trofeus = () => {
  return (
    <div className={styles.quadmetcs}>
          <div className={styles.quads}>
            <img src="./img/site.jpg" alt="" className={styles.metcs} />
            <h3>+50 SISTEMAS ENTREGUES</h3>
          </div>
          <div>
            <img src="./img/oclock.jpg" alt="" className={styles.metcs} />
            <h3>+ENTREGA RÁPIDA</h3>
          </div>
          <div>
            <img src="./img/troph.jpg" alt="" className={styles.metcs} />
            <h3>+EXPERIÊNCIA COMPROVADA</h3>
          </div>
        </div>
  )
}

export default Trofeus;
