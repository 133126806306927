import React from 'react';
import styles from './Sobrenos.module.css';

const Sobrenos = () => {
  return (
    <section className={styles.container} id='sobrenos'>
        <img src="./img/squad.jpg" alt=""  className={styles.image}/>
        <div className={styles.divider}></div>
        <div className={styles.content}>
            <h1 className={styles.title}>SOBRE NÓS</h1>
           <div className={styles.info}>
             <p>
               Somos impulsionados pela paixão por transformar dados em decisões inteligentes. 
               Na Smart Data Solutions, acreditamos no poder da informação para moldar o futuro dos negócios. 
               Com uma equipe dedicada de especialistas em análise e desenvolvimento de software, 
               estamos aqui para simplificar complexidades, 
               revelar insights profundos e abrir novos caminhos para o sucesso empresarial. 
               Junte-se a nós nesta jornada rumo à inovação, onde cada byte de dados conta uma história de oportunidades.
             </p>
           </div>
        </div>
        
    </section>
  )
}

export default Sobrenos;
