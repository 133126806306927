import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Importa estilos CSS padrão do Leaflet
import styles from './Contato.module.css';
import { FaWhatsapp, FaAt, FaMapMarkerAlt } from 'react-icons/fa';
import L from 'leaflet';

// Cria um ícone Leaflet personalizado
const customIcon = new L.Icon({
  iconUrl: `${process.env.PUBLIC_URL}/img/locale.png`,
  iconSize: [38, 38], // Tamanho do ícone
});

const Contato = () => {
  const position = [-5.074949797617586, -42.78143175110691];
  const address = 'Av. Homero Castelo Branco, 1036, Sala 11/12, São Cristóvão - Teresina, Piauí';

  const handleWhatsClick = () => {
    window.open("https://wa.me/558620180456", "_blank");
  };

  const handleDirectionsClick = () => {
    const destination = encodeURI(address);
    const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    window.open(url, "_blank");
  };

  return (
    <section className={styles.container} id='contato'>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <img src="./img/logowname.png" alt="Logo com nome" className={styles.image} />
        </div>
        <div className={styles.info}>
          <p>(86)2018-0456 <span><FaWhatsapp /></span></p>
          <p className={styles.text}>{address}<span><FaMapMarkerAlt/></span></p>
          <p>contato@smartdatasolutions.com.br <span><FaAt /></span></p>
          <button type='button' className={styles.button} onClick={handleWhatsClick}>ENTRAR EM CONTATO</button>
        </div>
      </div>
      <div className={styles.mapContainer}>
        <MapContainer center={position} zoom={17} style={{ width: '350px', height: '350px' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={customIcon}>
            <Popup>
              Smart Data Solutions <br />
              {address}
              <br />
              <button className={styles.mapButton} onClick={handleDirectionsClick}>
                Ver Direções
              </button>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </section>
  );
};

export default Contato;





















